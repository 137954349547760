import classnames from 'classnames';
import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import { parse as parseQueryString } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { selfDefinedUserTypes } from '@oup/shared-node-browser/user';
import AccountButton from '@oup/shared-front-end/src/components/AccountButton/AccountButton.js';
import Link from '../../components/Link/Link';
import Button, { buttonTypes } from '../../components/Button/Button.js';
import ImpersonationAccountButton from '../../components/ImpersonationAccountButton/ImpersonationAccountButton.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import USER_ROLES, { roleIsAtLeast, userRoleFriendlyNames } from '../../globals/userRoles.js';
import { switchOrg, triggerLogout } from '../../redux/reducers/identity.reducer.js';
import { getLogoutUrl } from '../../redux/sagas/appFunctions/authorisedFunctions/auth/handleLogout.saga.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import NGSAccountMenu from '../../components/NGSAccountMenu/NGSAccountMenu';
import {
  featureIsEnabled,
  socialIdpIsEnabled,
  getPlatformSupportUrl,
  getIntegrationPlatformKey,
  getEnvType
} from '../../globals/envSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import { toggleAccountWidget } from '../../redux/reducers/app.reducer.js';
import { openEditMyAccountModal as openEditMyAccountModalHub } from '../../redux/actions/hubUi';
import { openEditMyAccountModal } from '../../redux/actions/editUserAccountModal';
import getCurrentOrganisation from '../../redux/selectors/getCurrentOrganisation';
import getCurrentUser from '../../redux/selectors/getCurrentUser';
import getCurrentUserOrganisations from '../../redux/selectors/getCurrentUserOrganisations';
import styles from './SiteHeader.scss';
import isRegion from '../../utils/isRegion';
import OrbCourseSelector from '../../components/OrbCourseSelector/OrbCourseSelector.jsx';
import {
  epsPlatformOlb,
  isOrbMode,
  isCesMode,
  isHubMode,
  getCurrentPlatform,
  getPlatformFromQueryString,
  isEltAssessmentMode,
  isOteMode,
  isOicMode,
  isMflMode,
  isEbookSampleMode
} from '../../utils/platform.js';
import localStorageWithCache from '../../utils/localStorageWithCache';
import { PLATFORM_NAME } from '../../globals/appConstants';
import { redirectToAm } from '../../utils/url';
import { displayHeaderOrFooter } from '../../services/cptHelperMethods.js';

const { urls } = require('../../../lambda/common/urls.js');

export class SiteHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayHeader: true
    };
  }

  componentDidMount() {
    const { history } = this.props;
    this.unlisten = displayHeaderOrFooter(history, this.setDisplay);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setDisplay = value => {
    this.setState({ displayHeader: value });
  };

  _renderLogo = () => {
    const {
      localizedContent: { siteHeaders: content },
      orgSubscriptions,
      darkLayout,
      userSignedIn
    } = this.props;
    const hasBetaSubscription =
      Object.values(orgSubscriptions).length > 0 &&
      Object.values(orgSubscriptions).find(subscription => subscription.productDetails.market === 'AUSTRALASIA');

    // Show beta logo for other than Asia region
    const orbLogo = hasBetaSubscription ? GLYPHS.ORB_LOGO_BETA : GLYPHS.ORB_LOGO;
    const orbMobileLogo = hasBetaSubscription ? GLYPHS.ORB_LOGO_MOBILE_BETA : GLYPHS.ORB_LOGO_MOBILE;
    const oupLogo = darkLayout ? GLYPHS.OUP_LOGO_BLUE_WHITE : GLYPHS.OUP_LOGO_BLUE;
    const oupLogoMobile = darkLayout ? GLYPHS.OUP_LOGO_BLUE_WORDMARK_WHITE : GLYPHS.OUP_LOGO_BLUE_WORDMARK;
    const oteLogo = darkLayout ? GLYPHS.OTE_LOGO_WHITE : GLYPHS.OTE_LOGO_BLUE;
    const oteLogoMobile = darkLayout ? GLYPHS.OTE_LOGO_WHITE_MOBILE : GLYPHS.OTE_LOGO_BLUE_MOBILE;
    const oicLogo = userSignedIn ? GLYPHS.OIC_SLIMLINE_LOGO : GLYPHS.OIC_LOGO;
    const oicLogoMobile = userSignedIn ? GLYPHS.OIC_LOGO_MOBILE : GLYPHS.OIC_LOGO;

    let logo = isOrbMode()
      ? {
          desktopLogo: orbLogo,
          mobileLogo: orbMobileLogo,
          title: PLATFORM_NAME.ORB,
          svgViewBox: '0 0 45.203 12.811',
          svgViewBoxMobile: '0 0 100.0 63.5'
        }
      : {
          desktopLogo: oupLogo,
          mobileLogo: oupLogoMobile,
          title: PLATFORM_NAME.EPS,
          svgViewBox: '0 0 899.28 316.45',
          svgViewBoxMobile: '0 0 899.28 316.45'
        };

    logo = isOteMode()
      ? {
          desktopLogo: oteLogo,
          mobileLogo: oteLogoMobile,
          title: PLATFORM_NAME.OTE,
          svgViewBox: '0 0 45.203 12.812',
          svgViewBoxMobile: '0 0 100.0 63.5'
        }
      : logo;

    logo = isOicMode()
      ? {
          desktopLogo: oicLogo,
          mobileLogo: oicLogoMobile,
          title: PLATFORM_NAME.OIC,
          svgViewBox: userSignedIn ? '0 0 329 35' : '0 0 169 42',
          svgViewBoxMobile: userSignedIn ? '0 0 50 40' : '0 0 169 42'
        }
      : logo;

    return (
      isOrbMode() && (
        <>
          <SVGIcon glyph={logo.desktopLogo} title={logo.title} className={classnames(styles.desktopLogo)} />
          <SVGIcon className={styles.orbMobileLogo} glyph={logo.mobileLogo} title={logo.title} />
          <span className="a11y-hide">{content.homepage_text}</span>
        </>
      )
    );
  };

  _renderLogoLink = () => {
    const {
      userSignedIn,
      disableLogoLink,
      localizedContent: { siteHeaders: content }
    } = this.props;

    let logoURL = content.oup_logo_link;
    if (isOicMode()) {
      logoURL = urls.OIC_BASE_URL;
    } else if (isOrbMode()) {
      logoURL = content.orb_logo_link;
    }

    return disableLogoLink ? (
      <a id="navHeader-homeButton">{this._renderLogo()}</a>
    ) : (
      <a id="navHeader-homeButton" href={userSignedIn ? '/' : logoURL} data-testid="SITE_LOGO">
        {this._renderLogo()}
      </a>
    );
  };

  _renderBurgerMenuButton = () => {
    const {
      onToggleMenu,
      userSignedIn,
      userId,
      userRole,
      hubMenuSelected,
      selfSelectRoleModalOpen,
      hasPendingInvites,
      localizedContent: { siteHeaders: content }
    } = this.props;

    const { getKey } = localStorageWithCache;
    const userIdFromLs = get(JSON.parse(getKey('selectedRoleData')), ['userId'], '');

    const hideBurgerMenuOnSelfSelect =
      isHubMode() &&
      !roleIsAtLeast(USER_ROLES.MANAGED_USER, userRole) &&
      selfSelectRoleModalOpen &&
      userIdFromLs !== userId;

    if (!userSignedIn || hideBurgerMenuOnSelfSelect || hasPendingInvites) return null;
    const hubMenuButtonAriaLabel = `${content.toggle_navigation} ${hubMenuSelected ? 'expanded' : 'collapsed'}`;

    return (
      <li className={styles.menuButton}>
        <button
          type="button"
          id="navHeader-menuButton"
          onClick={onToggleMenu}
          className={classnames({ [styles.hubMenuSelected]: hubMenuSelected })}
          aria-label={isHubMode() ? hubMenuButtonAriaLabel : ''}
        >
          <SVGIcon glyph={GLYPHS.ICON_MENU} />
          <span className="a11y-hide">{content.toggle_navigation}</span>
        </button>
      </li>
    );
  };

  _renderMyAccountText = () => {
    const {
      localizedContent: { siteHeaders: content },
      hideMyAccountText = false
    } = this.props;

    if (isEltAssessmentMode()) {
      return (
        <li className={styles.myAccountText}>
          {hideMyAccountText ? (
            <div className={styles.myAccountText} />
          ) : (
            <div id="navHeader-myAccount">{isOteMode() ? '' : PLATFORM_NAME.OPT}</div>
          )}
        </li>
      );
    }

    return (
      <li className={styles.myAccountText}>
        {hideMyAccountText ? (
          <div className={styles.myAccountText} />
        ) : (
          <div id="navHeader-myAccount">{isCesMode() ? content.homepage_myAccount_text : ''}</div>
        )}
      </li>
    );
  };

  _renderHelpLink = () => {
    const {
      hideHelpLink,
      localizedContent: { siteHeaders: content }
    } = this.props;

    if (hideHelpLink) return null;

    let showExternalIcon = false;

    let platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());

    if (isRegion('kb')) {
      platform = 'kerboodle';
    }

    let linkHref = getPlatformSupportUrl(platform) || '/support';
    if (isMflMode()) {
      linkHref = APP_CONSTANTS.MFL_SUPPORT_LINK;
    }

    if (isEbookSampleMode()) {
      linkHref = APP_CONSTANTS.INSP_SUPPORT_HELP_LINK;
      showExternalIcon = true;
    }

    const target =
      isHubMode() ||
      isOrbMode() ||
      isCesMode() ||
      isOicMode() ||
      platform.includes(epsPlatformOlb) ||
      isEbookSampleMode()
        ? '_blank'
        : undefined;

    return (
      <li className={styles.helpButton}>
        {!redirectToAm() ? (
          <Link
            id="navHeader-supportButton"
            to={linkHref}
            openInNewTab
            target={target}
            role="button"
            dataTestId="GOTO_SUPPORT"
          >
            {!isOrbMode() ? <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} /> : null}
            <span className={styles.helpSupportText}>{isOrbMode() ? content.help_text : content.help_and_support}</span>
            {showExternalIcon && <SVGIcon className="gin-left1" glyph={GLYPHS.ICON_EXTERNAL_LINK} />}
          </Link>
        ) : null}
      </li>
    );
  };

  _handleSignIn = async () => {
    const { platform } = this.props;
    const url = await getIdpLoginUrl(null, { platform });
    window.location.href = url;
  };

  // eslint-disable-next-line class-methods-use-this
  _handleRegister = async () => {
    window.location.href = socialIdpIsEnabled(getCurrentPlatform()) ? '/register-choice' : '/register';
  };

  _renderUnauthorizedUserButtons = () => {
    const {
      userSignedIn,
      hideRegisterLink,
      hideSignInLink,
      localizedContent: { siteHeaders: content, oicRedirectToJanison }
    } = this.props;

    if (userSignedIn) return null;

    const oicStudentSignIn = () => {
      window.location.href = ['PROD', 'PREPROD'].includes(getEnvType())
        ? oicRedirectToJanison.student_sign_url_prod
        : oicRedirectToJanison.student_sign_url_uat;
    };

    return (
      <li className={styles.loginButton}>
        {!hideRegisterLink ? (
          <Button
            id="register-button"
            customClassName={styles.registerButton}
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS.ICON_USER_CIRCLE}
            disableExternalBehaviour
            onClick={this._handleRegister}
            text={content.registerButton_link_text}
            data-testid="GOTO_REGISTER"
          />
        ) : null}
        {!hideSignInLink &&
          (isOteMode() || isOicMode() ? (
            <>
              <Button
                id="signInTestTaker"
                type={classnames({
                  [buttonTypes.BLUE]: !isOicMode(),
                  [buttonTypes.OIC_GREEN]: isOicMode()
                })}
                glyph={classnames({
                  [GLYPHS.ICON_USER_CIRCLE]: !isOicMode(),
                  [GLYPHS.ICON_USER_CIRCLE_MONO]: isOicMode()
                })}
                text={content.sign_in_student_text}
                onClick={
                  () => (isOicMode() ? oicStudentSignIn() : this._handleSignIn(selfDefinedUserTypes.CLASS_STUDENT))
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                ariaLabel={content.sign_in_with_oxford_id_text}
                disableExternalBehaviour
                dataTestId="GOTO_LOGIN_TEST_TAKER"
              />
              <Button
                id="signInStaff"
                type={classnames({
                  [buttonTypes.GHOST_INVERTED]: !isOicMode(),
                  [buttonTypes.OIC_BLUE]: isOicMode()
                })}
                glyph={classnames({
                  [GLYPHS.ICON_USER_CIRCLE]: !isOicMode(),
                  [GLYPHS.ICON_USER_CIRCLE_MONO]: isOicMode()
                })}
                text={content.sign_in_staff_text}
                onClick={() => this._handleSignIn(selfDefinedUserTypes.CLASS_TEACHER)}
                ariaLabel={content.sign_in_with_oxford_id_text}
                disableExternalBehaviour
                dataTestId="GOTO_LOGIN_STAFF"
              />
            </>
          ) : (
            <Button
              id="signIn"
              type={buttonTypes.GHOST_INVERTED}
              glyph={GLYPHS.ICON_USER_CIRCLE}
              text={content.sign_in_text}
              onClick={this._handleSignIn}
              ariaLabel={content.sign_in_with_oxford_id_text}
              dataTestId="GOTO_LOGIN"
              disableExternalBehaviour
            />
          ))}
      </li>
    );
  };

  _renderAccountWidget = (isNgs = false) => {
    const {
      userSignedIn,
      hideWidgetDropDown,
      accountWidgetOpen,
      handleToggleAccountWidget,
      userFirstName,
      userLastName,
      orgId,
      canRegisterOrg,
      onOrgRegisterClick,
      onEditAccountClick,
      myOrgUrl,
      onOrgChange,
      orgChangeUrl
    } = this.props;

    if (!userSignedIn || hideWidgetDropDown) return null;

    const onOrgValueChanged = id => onOrgChange(id, orgChangeUrl);
    const isImpersonationMode = !!localStorage.getItem('impersonation-token');

    return (
      <li className={isImpersonationMode ? styles.impersonationAccountButton : styles.accountButton}>
        {isImpersonationMode && (
          <ImpersonationAccountButton
            buttonElementId="navHeader-accountWidget"
            icon={GLYPHS.ICON_IMPERSONATION}
            onClick={handleToggleAccountWidget}
            ariaLabel="User account management widget"
            isOpen={accountWidgetOpen}
          />
        )}

        {!isImpersonationMode && !redirectToAm() ? (
          <AccountButton
            id="navHeader-accountWidget"
            ariaAttributes={{ label: 'User account management widget' }}
            onClick={handleToggleAccountWidget}
            firstName={userFirstName}
            lastName={userLastName}
            active={accountWidgetOpen}
          />
        ) : null}
        {accountWidgetOpen && isNgs && (
          <NGSAccountMenu
            {...pick(this.props, ['logoutUrl', 'onLogout'])}
            buttonElementId="navHeader-ngs-accountWidget"
            onTogglePopup={handleToggleAccountWidget}
          />
        )}
        {accountWidgetOpen && !isNgs && (
          <AccountMenu
            {...pick(this.props, [
              'logoutUrl',
              'userFirstName',
              'userLastName',
              'userName',
              'userRole',
              'friendlyUserRole',
              'userOrganisations',
              'orgName',
              'orgRole',
              'customId',
              'onLogout'
            ])}
            orgId={orgId}
            buttonElementId="navHeader-accountWidget"
            onTogglePopup={handleToggleAccountWidget}
            onOrgRegisterClick={canRegisterOrg ? () => onOrgRegisterClick() : undefined}
            onEditAccountClick={onEditAccountClick}
            myOrgUrl={myOrgUrl}
            onOrgChange={onOrgValueChanged}
            orgChangeUrl={orgChangeUrl}
          />
        )}
      </li>
    );
  };

  render() {
    const {
      localizedContent: { siteHeaders: content },
      hideBurgerMenu,
      darkLayout
    } = this.props;

    const courses = [
      {
        name: 'Afrikaans',
        value: 'afrikaans'
      },
      {
        name: 'English',
        value: 'english',
        active: true
      }
    ];

    const isOicHome = isOicMode() && window.location.pathname === '/';
    let skipToMainContent = '';
    if (!isOicMode()) {
      skipToMainContent = (
        <a className="a11y-hide-focusable" href="#maincontent">
          {content.skip_to_main_content}
        </a>
      );
    }

    const { displayHeader } = this.state;

    return (
      displayHeader && (
        <header
          className={classnames(styles.siteHeader, {
            [styles.headerRestyling]: (!isOrbMode() && isOicMode()) || (!isOrbMode() && !isOicMode()),
            [styles.darkBackground]: darkLayout,
            [styles.headerOic]: isOicMode(),
            [styles.isOicHome]: isOicHome
          })}
        >
          {/* For keyboard & screenreader users: (Hidden accessibly. Visible on focus) */}
          {skipToMainContent}
          <div className="grid">
            <div className="row">
              <div id="navHeader" className="col">
                <nav aria-label="Primary">
                  <ul>
                    {/* Burger menu on mobile. Hidden by css on Desktop etc */}
                    {!hideBurgerMenu && this._renderBurgerMenuButton()}

                    {/* OUP Logo */}
                    <li
                      className={classnames({
                        [styles.orbLogo]: isOrbMode(),
                        [styles.oxfordLogo]: !isOrbMode() && !isOicMode(),
                        [styles.oicLogo]: isOicMode(),
                        [styles.isOicHome]: isOicHome
                      })}
                    >
                      {this._renderLogoLink()}
                    </li>

                    {/* My account text */}
                    {this._renderMyAccountText()}

                    {/* ORB only course selector */}

                    {isOrbMode() && featureIsEnabled('orb-multiple-courses') ? (
                      <li className={styles.courseSelector}>
                        <OrbCourseSelector courses={courses} />
                      </li>
                    ) : null}

                    {/* Support link (Note this is replicated in SubHeader menu for xs devices:) */}
                    {/* Also note that this is hidden by CSS when the BurgerMenu is visible. */}
                    {this._renderHelpLink()}

                    {this._renderUnauthorizedUserButtons()}
                    {this._renderAccountWidget()}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      )
    );
  }
}

export const connectRedux = connect(
  state => {
    const roleName = state.identity.role;
    const userSignedIn = !!roleName;
    const darkLayout = !(userSignedIn || isOrbMode() || isOicMode());
    const queryString = parseQueryString(window.location.search) || {};
    let currentUser = {};
    let currentOrg = {};

    if (userSignedIn) {
      currentUser = getCurrentUser(state) || {};
      currentOrg = getCurrentOrganisation(state) || {};
    }

    return {
      accountWidgetOpen: state.app.accountWidgetOpen,
      userSignedIn,
      darkLayout,
      platform: queryString.providerId || queryString.platform,
      canRegisterOrg: roleName === USER_ROLES.ORG_ADMIN || roleName === USER_ROLES.USER || !roleName,
      logoutUrl: getLogoutUrl(),
      userFirstName: currentUser.firstname,
      userLastName: currentUser.lastname,
      userName: currentUser.username || currentUser.email,
      friendlyUserRole: userRoleFriendlyNames(roleName),
      userRole: roleName,
      userOrganisations: getCurrentUserOrganisations(state),
      orgName: currentOrg.name || '',
      orgRole: currentOrg.role || '',
      customId: currentOrg.customId,
      orgSubscriptions: state.subscriptions,
      orgSubscriptionsLoading: state.search.orgSubscriptions
        ? state.search.orgSubscriptions.loading
        : !!state.identity.currentOrganisationId &&
          state.invites.invites &&
          !(state.invites.invites.length > 0) &&
          !localStorage.getItem('showStartUpPages')
    };
  },
  (dispatch, props) => ({
    handleToggleAccountWidget: () => dispatch(toggleAccountWidget()),
    onLogout: () => {
      dispatch(toggleAccountWidget());
      dispatch(triggerLogout());
    },
    onOrgRegisterClick: () => {
      dispatch(toggleAccountWidget());
      props.history.push(`/org/register`);
    },
    onOrgChange: (orgId, returnUrl) => {
      dispatch(toggleAccountWidget());
      dispatch(switchOrg(orgId, null, returnUrl));
    },
    onEditAccountClick: () => {
      dispatch(isHubMode() ? openEditMyAccountModalHub() : openEditMyAccountModal());
      dispatch(toggleAccountWidget());
    }
  })
);

SiteHeader.propTypes = {
  orgId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  /** Boolean which toggles showing the global button popup */
  accountWidgetOpen: PropTypes.bool.isRequired,
  /** True if a user is signed in, will control rendering the AccountWidget */
  userSignedIn: PropTypes.bool.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  /** Action to toggle the global popup open value */
  handleToggleAccountWidget: PropTypes.func.isRequired,
  // Sub nav props
  onToggleMenu: PropTypes.func.isRequired,
  // platform/providerId passed as a query param
  platform: PropTypes.string,
  hideWidgetDropDown: PropTypes.bool.isRequired,
  hideSignInLink: PropTypes.bool.isRequired,
  hideRegisterLink: PropTypes.bool.isRequired,
  hideHelpLink: PropTypes.bool.isRequired,
  disableLogoLink: PropTypes.bool.isRequired,
  canRegisterOrg: PropTypes.bool,
  onOrgRegisterClick: PropTypes.func.isRequired,
  onEditAccountClick: PropTypes.func,
  onOrgChange: PropTypes.func.isRequired,
  myOrgUrl: PropTypes.string,
  userId: PropTypes.string,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  orgChangeUrl: PropTypes.string,
  hideMyAccountText: PropTypes.bool,
  hideBurgerMenu: PropTypes.bool,
  hubMenuSelected: PropTypes.bool,
  orgRole: PropTypes.string,
  orgSubscriptions: PropTypes.array,
  orgSubscriptionsLoading: PropTypes.bool,
  friendlyUserRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  hasPendingInvites: PropTypes.bool.isRequired,
  darkLayout: PropTypes.bool,
  history: PropTypes.object
};

export default compose(
  withLocalizedContent('siteHeaders', 'oicRedirectToJanison'),
  withRouter,
  connectRedux
)(SiteHeader);
