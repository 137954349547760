import { pickBy, partition } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Link from '../../../../components/Link/Link';
import withGetYearGroupOptions from '../../../../language/withGetYearGroupOptions';
import Badge from '../../../../components/Badge/Badge';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import SearchStatus from '../../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import UserListing from '../../../../components/UserListing/UserListing';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import colors from '../../../../globals/colors';
import { orgRoles } from '../../../../globals/orgRoles';
import { searchStudentSortOptions } from '../../../../globals/searchFilters';
import { featureIsEnabled, isLocal } from '../../../../globals/envSettings';
import userRoles from '../../../../globals/userRoles';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { UserEnrolPanel } from '../../../../panels';
import ImportUsersPanel from '../../../../panels/ImportUsersPanel/ImportUsersPanel';
import UserArchivePanel from '../../../../panels/UserArchivePanel';
import UserRestorePanel from '../../../../panels/UserRestorePanel';
import { setManagedUser, changeManagedUserPasswordRequest } from '../../../../redux/actions/managedUser';
import { setStudentsToRestore } from '../../../../redux/actions/restoreUsers';
import ManagedUserSignInCardPanel from '../../../../panels/ManagedUserSignInCard/ManagedUserSignInCardPanel';
import {
  isAtLeastTeacherAdmin,
  createAuthorizeUnlockUser,
  createAuthorizeRegisterUser,
  createAuthorizeRemoveUser,
  createAuthorizeRemoveUserBasedOnRole,
  createAuthorizeDownloadSignInCard
} from '../../../../redux/selectors/authorization/user';
import { closeForm, setStudentsToArchive, showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer';
import { createAuthorizeUpdateAssignments } from '../../../../redux/selectors/authorization/organization';
import { resetJourney } from '../../../../redux/reducers/assignLearningMaterial.reducer';
import {
  setPageOrgStudent,
  setSortOrgStudent,
  setTerm,
  triggerSearchOrgStudent,
  setFilterOrgStudent
} from '../../../../redux/reducers/data/search.reducer';
import { clearSelectedUser, setStudentSelected } from '../../../../redux/reducers/organisationPage.reducer';
import { clearStoreClassrooms } from '../../../../redux/reducers/data/classrooms';
import { setAccountToUnlock } from '../../../../redux/reducers/unlockAccount.reducer';
import EnrolUser from '../StaffTab/panels/EnrolUser/EnrolUser';
import AssignLearningMaterial from '../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import UnlockAccount from '../../../panels/UnlockAccount/UnlockAccount';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import { setStudentsToDownload, resetSignInCard } from '../../../../redux/reducers/userSignInCard.reducer';
import getCurrentOrganisation from '../../../../redux/selectors/getCurrentOrganisation';
import getUrl from '../../../../globals/urls';
import ProductFinderPanel from '../../../../components/ProductFinder/ProductFinderPanel';
import { isHubMode } from '../../../../utils/platform';
import { resetForm } from '../../../../redux/reducers/enrolUser.reducer';

class StudentsTab extends Component {
  static _getFilterOptions(setFilterAction, filters, organisation, content) {
    const filterOptions = [
      {
        text: content.filter_options_active,
        id: 'orgStudentsSearch-filterActive',
        name: 'orgStudentsSearch-filterActive',
        value: 'active',
        checked: filters.active,
        onChange: setFilterAction
      },
      {
        text: content.filter_options_archived_students,
        id: 'orgStudentsSearch-filterArchived',
        name: 'orgStudentsSearch-filterArchived',
        value: 'archived',
        checked: filters.archived,
        onChange: setFilterAction
      }
    ];
    if (featureIsEnabled('lock-account')) {
      filterOptions.push({
        text: content.filter_options_locked_students,
        id: 'orgStudentsSearch-filterLocked',
        name: 'orgStudentsSearch-filterLocked',
        value: 'locked',
        checked: filters.locked,
        onChange: setFilterAction
      });
    }
    if (organisation.role !== orgRoles.PRIMARY_SCHOOL)
      filterOptions.push({
        text: content.filter_options_invited_students,
        id: 'orgStudentsSearch-filterInvited',
        name: 'orgStudentsSearch-filterInvited',
        value: 'invited',
        checked: filters.invited,
        onChange: setFilterAction
      });

    return filterOptions;
  }

  componentDidMount() {
    const { clearSelectedUserAction } = this.props;
    clearSelectedUserAction();
  }

  _handleUnlockAccount = () => {
    const {
      setManagedUserAction,
      setAccountToUnlockAction,
      orgId,
      selectedStudentIds,
      people,
      changeManagedUserPasswordRequestAction
    } = this.props;
    const userId = selectedStudentIds[0];

    setManagedUserAction({
      userId,
      user: {
        firstName: people[userId].firstname,
        lastName: people[userId].lastname,
        username: people[userId].email && people[userId].email !== null ? people[userId].email : people[userId].username
      },
      orgId
    });

    setAccountToUnlockAction(selectedStudentIds);
    if (featureIsEnabled('lock-account')) {
      changeManagedUserPasswordRequestAction(orgId, selectedStudentIds);
    }
  };

  _handleRemoveUser = id => {
    const { setStudentsToArchiveAction, history } = this.props;
    setStudentsToArchiveAction(id);
    history.push(safePanelLink('archiveUsers'));
  };

  _handleRestoreUser = id => {
    const { orgId, setStudentsToRestoreAction, history } = this.props;
    setStudentsToRestoreAction({ userIds: [id], orgId });
    history.push(safePanelLink('restoreUsers'));
  };

  _getProductFinderContextName = () => {
    const {
      selectedStudentIds,
      people,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedStudentIds.length === 1
      ? `${people[selectedStudentIds[0]].firstname || ''} ${people[selectedStudentIds[0]].lastname || ''}`
      : `${selectedStudentIds.length} ${productFinderContent.students_text}`;
  };

  render() {
    const {
      orgId,
      tabName,
      panelName,
      orgName,
      curriculumType,
      isOrgArchived,
      isPrimarySchool,
      people,
      selectedStudentIds,
      searchTerm,
      sort,
      page,
      filters,
      totalResults,
      showConfirmModal,
      orgStudentsDataRecency,
      history: { push },
      triggerSearchAction,
      setSearchTerm,
      setSortAction,
      setPageAction,
      setFilterAction,
      selectStudent,
      setStudentsToArchiveAction,
      setStudentsToDownloadAction,
      setStudentsToRestoreAction,
      closeModalAction,
      resetJourneyAction,
      showModalAction,
      clearSelectedUserAction,
      clearStoreClassroomsAction,
      localizedContent: {
        mySchoolStudentsTab: content,
        removeLearningMaterialModal: removeMaterialModalContent,
        managedUserSignInCardPanel: managedUserSignInCardContent
      },
      canRemoveUser = false,
      canRemoveUserBasedOnRole = false,
      canManageAssignments = false,
      canUnlockUser = false,
      canRegisterUser = false,
      canViewUpdatePane = false,
      yearGroupOptions,
      paginatedUsers,
      // currentUsers,
      canDownloadSignInCard = false,
      onResetSignInCard,
      loadingResults,
      organisation,
      reset
    } = this.props;

    const mangedUserEnrolPanelName = featureIsEnabled('managed-user-enrol') ? 'enrolUsers' : 'importUsers';
    const addStudentsUrl = safePanelLink(isPrimarySchool ? mangedUserEnrolPanelName : 'addStudents');
    const addStudentsLabel = isPrimarySchool ? content.add_students_text : content.invite_students_text;
    const placeholderLabel = isPrimarySchool ? content.search_students_label : content.search_students_label_secondary;
    const canUnlockAccount = selectedStudentIds.length && selectedStudentIds.every(id => people[id].isLocked);
    const [restorableSelectedStudentIds, removableSelectedStudentIds] = partition(
      selectedStudentIds,
      id => people[id].orgArchiveStatus === APP_CONSTANTS.USER_STATUS.ARCHIVED
    );
    const hasSelectedRestorableUsers =
      canRemoveUser &&
      restorableSelectedStudentIds.length &&
      !(featureIsEnabled('remove-restore-archived-manageduser') && isPrimarySchool);

    const shouldShowDownloadSignInCardButton =
      canDownloadSignInCard &&
      isPrimarySchool &&
      !(
        featureIsEnabled('remove-restore-archived-manageduser') &&
        restorableSelectedStudentIds.length === selectedStudentIds.length
      );
    const hasRemovalUsersBasedOnRole = canRemoveUserBasedOnRole;
    const studentShouldBeSelectable =
      (canUnlockUser && canUnlockAccount) || canRemoveUserBasedOnRole || canManageAssignments;
    return (
      <div>
        <ListPageControls
          idPrefix="orgStudentsSearch"
          searchInputLabel={placeholderLabel}
          searchInputPlaceholder={placeholderLabel}
          searchInputValue={searchTerm}
          searchInputOnChange={term => setSearchTerm(term, yearGroupOptions)}
          searchInputOnSubmit={triggerSearchAction}
          newButtonText={addStudentsLabel}
          disabled={isOrgArchived}
          newButtonTo={canRegisterUser ? addStudentsUrl : null}
          filterOptions={StudentsTab._getFilterOptions(setFilterAction, filters, organisation, content)}
          sortOnChange={setSortAction}
          sortOptions={searchStudentSortOptions('orgStudentsSearch', sort, !isPrimarySchool)}
          ariaControls="searchResults"
          loading={orgStudentsDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={orgStudentsDataRecency.synced}
          loadedMessage={content.loaded_message}
          showSkeletonLoader={loadingResults}
        />
        <SearchStatus
          searchSource="orgStudents"
          noResultsFoundContent={
            <div id="searchResults-students-noResults" className="grid">
              <div className="row">
                <div id="searchResults" className="col" role="region" aria-live="polite" aria-atomic="true">
                  <p className="gin-top1 gin-bot1">{content.no_search_results}</p>
                  {canRegisterUser && !isOrgArchived && <Link to={addStudentsUrl}>{addStudentsLabel}</Link>}
                </div>
              </div>
            </div>
          }
        />
        {!loadingResults && Object.keys(paginatedUsers).length > 0 ? (
          <div className="grid horizantal-scroll-mobile">
            <div className="row">
              <div
                id="searchResults"
                className="col gin-top2"
                role="region"
                aria-live="polite"
                aria-atomic="true"
                aria-label="School students list"
              >
                <UserListing
                  className="gin-top2"
                  items={paginatedUsers}
                  heading={content.students_text}
                  showYearGroups
                  orgCurriculumType={curriculumType}
                  selectedItems={selectedStudentIds}
                  processingItems={orgStudentsDataRecency.syncing ? orgStudentsDataRecency.ids : []}
                  onItemLabelClick={id => {
                    if (!isLocal()) {
                      window.newrelic.interaction().actionText('Open student profile');
                    }
                    push(getUrl({ type: 'PROFILE_USER', orgId, userId: id }));
                  }}
                  onItemSelect={id => selectStudent(id, !selectedStudentIds.includes(id))}
                  preventArchivedLinkClick={isPrimarySchool}
                  {...(studentShouldBeSelectable
                    ? {
                        selectable: Object.keys(pickBy(paginatedUsers, user => !user.isDeleted))
                      }
                    : {})}
                  {...(canRemoveUserBasedOnRole
                    ? {
                        onRemoveClick: this._handleRemoveUser,
                        onRestoreClick: this._handleRestoreUser
                      }
                    : {})}
                  selectAllEnabled
                />

                {totalResults > 10 ? (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="orgStudentsSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={setPageAction}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <SlideupPanel isOpen={!!selectedStudentIds.length}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '2rem' }}>
              <Badge backgroundColor={colors.DASHBOARD} value={selectedStudentIds.length} />
              <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_text}</span>
            </div>
            {canUnlockUser && canUnlockAccount ? (
              <LinkWithIcon
                id="openUnlockAccounts"
                to={safePanelLink('unlockAccount')}
                action={this._handleUnlockAccount}
                text={content.unlock_account}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
            {canManageAssignments && !isPrimarySchool ? (
              <LinkWithIcon
                id="openAssignToStudents"
                to={safePanelLink('assignMaterial')}
                text={content.assign_learning_material_text}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}

            {hasSelectedRestorableUsers ? (
              <LinkWithIcon
                id="restoreUsersLink"
                to={safePanelLink('restoreUsers')}
                action={() => setStudentsToRestoreAction({ userIds: restorableSelectedStudentIds, orgId })}
                text={content.restore_student_to_organisation}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}

            {shouldShowDownloadSignInCardButton ? (
              <LinkWithIcon
                id="viewSignInCardLink"
                to={safePanelLink('viewSignInCard')}
                action={() => setStudentsToDownloadAction(selectedStudentIds)}
                text={content.download_sign_in_card}
                subtext={content.download_sign_in_card_subtext}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}

            {hasRemovalUsersBasedOnRole && removableSelectedStudentIds.length ? (
              <LinkWithIcon
                id="archiveUsersLink"
                to={safePanelLink('archiveUsers')}
                action={() => setStudentsToArchiveAction(removableSelectedStudentIds)}
                text={content.remove_student_from_organisation}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
          </div>
        </SlideupPanel>

        {canRegisterUser ? (
          <div>
            <PopoutPanel
              id="enrolUserPopout"
              ariaLabel={content.aria_label_add_students}
              isOpen={panelName === 'addStudents'}
            >
              <EnrolUser
                orgId={orgId}
                context={APP_CONSTANTS.ORG_STUDENTS}
                closePanel={() => showModalAction(true)}
                onComplete={() => push(safePanelUnlink('addStudents'))}
              />
            </PopoutPanel>
            <UserEnrolPanel
              id="enrolManagedUserPopout"
              ariaLabel={content.aria_label_add_students}
              orgId={orgId}
              orgName={orgName}
              orgCurriculumType={curriculumType}
              orgStudentsDataRecency={orgStudentsDataRecency}
              isOpen={panelName === 'enrolUsers'}
              onShowImportUsers={() => {
                push(safePanelUnlink('enrolUsers'));
                push(safePanelLink('importUsers'));
              }}
              onClosePanel={() => push(safePanelUnlink('enrolUsers'))}
            />
            <ImportUsersPanel
              id="bulkEnrolManagedUserPopout"
              ariaLabel={content.aria_label_add_students}
              curriculumType={curriculumType}
              open={panelName === 'importUsers'}
              closePanel={() => showModalAction(true)}
              onComplete={() => push(safePanelUnlink('importUsers'))}
            />
          </div>
        ) : null}

        {canUnlockUser && canUnlockAccount ? (
          <UnlockAccount
            orgId={orgId}
            userIds={selectedStudentIds}
            people={people}
            panelId="unlockAccountsPopout"
            ariaLabel={content.aria_label_unlock_account}
            open={tabName === 'students' && panelName === 'unlockAccount'}
            onClose={() => push(safePanelUnlink('unlockAccount'))}
          />
        ) : null}
        {canManageAssignments ? (
          <PopoutPanel
            id="assignToStudentsPopout"
            ariaLabel={content.aria_label_assign_material_to_students}
            isOpen={tabName === 'students' && panelName === 'assignMaterial'}
          >
            {isHubMode() ? (
              <ProductFinderPanel
                orgId={orgId}
                selectedUsers={{ studentIdList: [...selectedStudentIds] }}
                contextName={this._getProductFinderContextName()}
                onClose={() => {
                  showModalAction(true);
                }}
                onComplete={() => {
                  clearSelectedUserAction();
                  push(safePanelUnlink('assignMaterial'));
                }}
                // needed for polling. will be removed when polling is removed
                context="ORG_STUDENTS"
              />
            ) : (
              <AssignLearningMaterial
                context="ORG_STUDENTS"
                orgId={orgId}
                closePopoutAction={() => {
                  showModalAction(true);
                }}
                onComplete={() => {
                  clearSelectedUserAction();
                  push(safePanelUnlink('assignMaterial'));
                }}
              />
            )}
          </PopoutPanel>
        ) : null}
        {canViewUpdatePane ? (
          <UserArchivePanel
            id="archiveUsersPopout"
            ariaLabel={content.aria_label_archive_users}
            orgId={orgId}
            userType={content.students_text.toLowerCase()}
            open={tabName === 'students' && panelName === 'archiveUsers'}
            isPrimarySchool={isPrimarySchool}
            onClosePanel={() => showModalAction(true)}
            onComplete={() => {
              push(safePanelUnlink('archiveUsers'));
              clearSelectedUserAction();
            }}
          />
        ) : null}
        {canViewUpdatePane ? (
          <UserRestorePanel
            id="restoreUsersPopout"
            ariaLabel={content.aria_label_restore_users}
            orgId={orgId}
            userType="students"
            isOpen={tabName === 'students' && panelName === 'restoreUsers'}
            onComplete={() => {
              push(safePanelUnlink('restoreUsers'));
              clearSelectedUserAction();
            }}
            onClosePanel={() => showModalAction(true)}
          />
        ) : null}
        {canDownloadSignInCard ? (
          <ManagedUserSignInCardPanel
            id="ManagedUserSignInCardPanelPopout"
            ariaLabel={managedUserSignInCardContent.aria_label_archive_users}
            orgId={orgId}
            userType="students"
            open={tabName === 'students' && panelName === 'viewSignInCard'}
            onClosePanel={() => showModalAction(true)}
            onComplete={() => {
              push(safePanelUnlink('viewSignInCard'));
              clearSelectedUserAction();
            }}
          />
        ) : null}

        {showConfirmModal ? (
          <ConfirmationModal
            title={removeMaterialModalContent.title}
            body={removeMaterialModalContent.body}
            positiveClickText={removeMaterialModalContent.positiveClickText}
            negativeClickText={removeMaterialModalContent.negativeClickText}
            positiveClick={() => {
              reset();
              // Close the Popout panel
              showModalAction(false);
              // Remove the multiple URL possibilities to redirect the user
              setTimeout(closeModalAction, 300);
              setTimeout(resetJourneyAction, 300);
              push(safePanelUnlink('archiveUsers'));
              push(safePanelUnlink('viewSignInCard'));
              push(safePanelUnlink('assignMaterial'));
              push(safePanelUnlink('addStudents'));
              push(safePanelUnlink('importUsers'));
              push(safePanelUnlink('unlockAccount'));
              push(safePanelUnlink('enrolUsers'));
              push(safePanelUnlink('restoreUsers'));
              clearSelectedUserAction();
              clearStoreClassroomsAction();
              onResetSignInCard();
            }}
            negativeClick={() => showModalAction(false)}
          />
        ) : null}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withGetYearGroupOptions,
  withSearchInitialiser({
    searchSource: 'orgStudents',
    initialFilters: {
      roles: [userRoles.LEARNER, userRoles.MANAGED_USER],
      active: true,
      invited: true,
      archived: false,
      locked: true,
      isOrgStudentTab: true // check for orgStudentTab
    }
  }),
  withLocalizedContent(
    'mySchoolStudentsTab',
    'removeLearningMaterialModal',
    'managedUserSignInCardPanel',
    'productFinder'
  ),
  withDataRecency('orgStudents'),
  connect(
    (state, { orgId, getYearGroupOptions }) => {
      const organisation = state.organisations.data[orgId];
      const isPrimarySchool = state.organisations.data[orgId].role === orgRoles.PRIMARY_SCHOOL;
      const targetUserRole = isPrimarySchool ? userRoles.MANAGED_USER : userRoles.LEARNER;
      return {
        userId: state.identity.userId,
        people: state.people.data,
        orgName: organisation.name || '',
        curriculumType: organisation.curriculumType || '',
        isOrgArchived: !!organisation.archived,
        isPrimarySchool,
        selectedStudentIds: state.organisationPage.selectedStudentIds,
        searchTerm: state.search.orgStudents.term,
        sort: state.search.orgStudents.sort,
        page: state.search.orgStudents.page,
        filters: state.search.orgStudents.filters,
        paginatedUsers: state.search.orgStudents.paginatedUserList,
        currentUsers: state.search.orgStudents.currentUsersList,
        totalResults: state.search.orgStudents.totalResults,
        showConfirmModal: state.archiveUsers.showModal,
        canUnlockUser: createAuthorizeUnlockUser(state)({ orgId }),
        canRegisterUser: createAuthorizeRegisterUser(state)({ orgId }) && !!orgId,
        canManageAssignments: createAuthorizeUpdateAssignments(state)({ orgId }),
        canViewUpdatePane: isAtLeastTeacherAdmin(state) && !!orgId,
        canRemoveUser: createAuthorizeRemoveUser(state)({ orgId, targetUserRole }),
        canRemoveUserBasedOnRole: createAuthorizeRemoveUserBasedOnRole(state)({ orgId, targetUserRole }),
        canDownloadSignInCard: createAuthorizeDownloadSignInCard(state)({ orgId, targetUserRole }),
        yearGroupOptions: getYearGroupOptions(organisation.curriculumType),
        loadingResults: state.search.orgStudents ? state.search.orgStudents.loading : false,
        organisation: getCurrentOrganisation(state),
        orgRole: state.organisations.data[state.identity.currentOrganisationId]?.role
      };
    },
    {
      setSearchTerm: (term, yearGroupOptions) => setTerm('orgStudents', term, yearGroupOptions),
      setSortAction: sort => setSortOrgStudent('orgStudents', sort[0]),
      setPageAction: page => setPageOrgStudent('orgStudents', page),
      setFilterAction: (valueName, value) => setFilterOrgStudent('orgStudents', valueName, value),
      triggerSearchAction: () => triggerSearchOrgStudent('orgStudents'),
      selectStudent: setStudentSelected,
      clearSelectedUserAction: clearSelectedUser,
      clearStoreClassroomsAction: clearStoreClassrooms,
      setStudentsToArchiveAction: setStudentsToArchive,
      setStudentsToDownloadAction: setStudentsToDownload,
      setStudentsToRestoreAction: setStudentsToRestore,
      setAccountToUnlockAction: setAccountToUnlock,
      closeModalAction: closeForm,
      resetJourneyAction: resetJourney,
      showModalAction: showClosePanelModal,
      setManagedUserAction: payload => setManagedUser(payload),
      changeManagedUserPasswordRequestAction: (orgId, userId) => changeManagedUserPasswordRequest(orgId, userId),
      onResetSignInCard: resetSignInCard,
      reset: resetForm
    }
  )
)(StudentsTab);

StudentsTab.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  curriculumType: PropTypes.string.isRequired,
  isOrgArchived: PropTypes.bool.isRequired,
  tabName: PropTypes.string,
  panelName: PropTypes.string,
  people: PropTypes.object.isRequired,
  isPrimarySchool: PropTypes.bool.isRequired,
  selectedStudentIds: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  totalResults: PropTypes.number.isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  orgStudentsDataRecency: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  selectStudent: PropTypes.func.isRequired,
  setStudentsToArchiveAction: PropTypes.func.isRequired,
  setStudentsToDownloadAction: PropTypes.func.isRequired,
  setStudentsToRestoreAction: PropTypes.func.isRequired,
  closeModalAction: PropTypes.func.isRequired,
  resetJourneyAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  clearSelectedUserAction: PropTypes.func.isRequired,
  clearStoreClassroomsAction: PropTypes.func.isRequired,
  setAccountToUnlockAction: PropTypes.func.isRequired,
  setManagedUserAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object,
  canUnlockUser: PropTypes.bool,
  canManageAssignments: PropTypes.bool,
  canRegisterUser: PropTypes.bool,
  canViewUpdatePane: PropTypes.bool,
  canRemoveUser: PropTypes.bool,
  canRemoveUserBasedOnRole: PropTypes.bool,
  yearGroupOptions: PropTypes.object.isRequired,
  paginatedUsers: PropTypes.object.isRequired,
  currentUsers: PropTypes.object.isRequired,
  isRefreshed: PropTypes.bool,
  canDownloadSignInCard: PropTypes.bool,
  onResetSignInCard: PropTypes.func.isRequired,
  changeManagedUserPasswordRequestAction: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool,
  organisation: PropTypes.object.isRequired,
  orgRole: PropTypes.oneOf(Object.keys(orgRoles)),
  reset: PropTypes.func.isRequired
};
